import React from "react";
import { graphql } from "gatsby";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import SEO from "../components/seo";
import illusPresse from "../components/illusPresse.svg";

const formatUrl = URL => {
  if (URL.startsWith("http")) {
    return URL;
  } else {
    return URL.split("/").pop();
  }
};

class InfoIndex extends React.Component {
  render() {
    const { data } = this.props;
    const { posts } = data;

    const preview = post => {
      const { title, slug, URL, date, type } = post.frontmatter;

      return (
        <a href={URL} key={slug} target="_blank" rel="noopener noreferrer">
          <article className="max-w-3xl mx-auto cursor-pointer py-8 bg-white">
            <div className="mb-2">
              <span className="mr-2">{date}</span>
              <span className="font-hairline">#</span>
              <span className="ml-2 font-semibold">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </span>
            </div>
            <div className="text-xl mb-2 font-semibold">{title}</div>
            <div className="hover:underline text-action sm:truncate">
              {formatUrl(URL)}
            </div>
          </article>
        </a>
      );
    };

    return (
      <Base>
        <SEO title="Solution de téléexpertise - Presse" />
        <div>
          <div className="py-16 bg-gray-100">
            <Container>
              <h1 className="text-4xl font-bold">Presse</h1>
            </Container>
          </div>
        </div>
        <Container>
          <div className="flex lg:flex-row flex-col-reverse lg:my-16 my-8">
            <div className="lg:w-4/6">
              <div className="lg:pr-16 mx-auto -my-8">
                {posts.nodes.map(post => {
                  return preview(post);
                })}
              </div>
            </div>
            <div className="lg:w-2/6">
              <div
                className="max-w-xs mx-auto mb-8 lg:mb-0 bg-action-100 rounded-lg p-8 lg:sticky"
                style={{ top: "8rem" }}
              >
                <a
                  href="https://drive.google.com/drive/folders/1pFgCTGwORfV7eBnue2T4uJmNSIekqSE8?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-action text-xl text-center font-bold mb-2">
                    Kit presse
                  </div>
                  <div className="text-action-800 text-center hover:underline mb-4">
                    Télécharger notre dossier de presse
                  </div>
                  <img
                    alt="Dossier de presse"
                    src={illusPresse}
                    className="hidden lg:block w-32 mx-auto"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Base>
    );
  }
}

export default InfoIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//presse/[^/]+/[^/]+.md$/" }
        frontmatter: { URL: { ne: null } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          URL
          date(formatString: "DD MMMM YYYY", locale: "fr")
          title
          type
        }
      }
    }
  }
`;
